import boston from '../../assets/img/nuarca_map_boston_500px.png';
import cork from '../../assets/img/nuarca_map_cork_rev_500px.png';
import bangladesh from '../../assets/img/nuarca_map_bangladesh_rev_500px.png';
import './style.css';


export default function About() {
    return (
        <section id="about_nuarca" className="nucomply_assistant text-dark p-5">
        <div className="container">
            <div className="row">

                <div className="about-block">
                    <div className="image_bumpin float-sm-start d-none d-sm-block">
                        <div className="3 align-self-start carousel slide  carousel-fade " data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active" data-interval="2500">
                                    <img src={boston} className="d-block w-100" alt="..."/>
                                </div>
                                <div className="carousel-item" data-interval="2500">
                                    <img src={cork} className="d-block w-100" alt="..."/>
                                </div>
                                <div className="carousel-item" data-interval="2500">
                                    <img src={bangladesh} className="d-block w-100" alt="..."/>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='about-text'>
                        <p className="text-light">NuArca's Corporate Headquarters is located in Woburn Massachusetts and has invested in building development execution capabilities in Boston, Mass, Dhaka, Bangladesh and Cork, Ireland. Our capacity and commitment to active participation in leading blockchain open source platforms means that we are ready to flexibly assist our customers in building solutions and systems that meet their objectives.
                        </p>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
    )
}
